const ORGANIZATION_TICKET_VISIBILITY = 'organization'
const PROPERTY_TICKET_VISIBILITY = 'property'
const PROPERTY_AND_SPECIALIZATION_VISIBILITY = 'propertyAndSpecialization'
const ASSIGNED_TICKET_VISIBILITY = 'assigned'
const TICKET_VISIBILITY_OPTIONS = [
    ORGANIZATION_TICKET_VISIBILITY,
    PROPERTY_TICKET_VISIBILITY,
    PROPERTY_AND_SPECIALIZATION_VISIBILITY,
    ASSIGNED_TICKET_VISIBILITY,
]

const TIN_LENGTH = 10
const DEFAULT_ORGANIZATION_TIMEZONE = 'Europe/Moscow'

const MANAGING_COMPANY_TYPE = 'MANAGING_COMPANY'
const HOLDING_TYPE = 'HOLDING'
const SERVICE_PROVIDER_TYPE = 'SERVICE_PROVIDER'

const ORGANIZATION_TYPES = [
    MANAGING_COMPANY_TYPE,
    HOLDING_TYPE,
    SERVICE_PROVIDER_TYPE,
]

const ORGANIZATION_NAME_PREFIX_AND_QUOTES_REGEXP = /ООО|"|«|»|“|”|„|‟/ig

const DELETED_ORGANIZATION_NAME = 'Deleted Organization'

const MIN_ROLE_NAME_LENGTH = 1
const MAX_ROLE_NAME_LENGTH = 128
const MAX_ROLE_DESCRIPTION_LENGTH = 1024
const MAX_ROLE_COUNT = 40

const DEFAULT_ROLES = {
    // Administrator role is required for mutation logic
    'Administrator': {
        'isDefault': true,
        'isEditable': false,
        'name': 'employee.role.Administrator.name',
        'description': 'employee.role.Administrator.description',
        'canManageOrganization': true,
        'canReadEmployees': true,
        'canManageEmployees': true,
        'canInviteNewOrganizationEmployees': true,
        'canManageRoles': true,
        'canManageIntegrations': true,
        'canImportBillingReceipts': true,
        'canManageProperties': true,
        'canReadTickets': true,
        'canManageTickets': true,
        'canReadContacts': true,
        'canManageContacts': true,
        'canManageContactRoles': true,
        'canManageTicketComments': true,
        'canManageMeters': true,
        'canManageMeterReadings': true,
        'canShareTickets': true,
        'canBeAssignedAsResponsible': true,
        'canBeAssignedAsExecutor': true,
        'canReadPayments': true,
        'canReadBillingReceipts': true,
        'canReadIncidents': true,
        'canManageIncidents': true,
        'canManageTicketPropertyHints': true,
        'canManagePropertyScopes': true,
        'canManageBankAccounts': true,
        'canManageBankAccountReportTasks': true,
        'canManageBankIntegrationAccountContexts': true,
        'canManageBankIntegrationOrganizationContexts': true,
        'canManageBankContractorAccounts': true,
        'canManageBankTransactions': true,
        'canManageBankAccountReports': true,
        'canManageCallRecords': true,
        'canDownloadCallRecords': true,
        'ticketVisibilityType': ORGANIZATION_TICKET_VISIBILITY,
        'canManageNewsItems': true,
        'canManageNewsItemTemplates': true,
        'canManageMobileFeatureConfigs': true,
        'canManageB2BApps': true,
        'canReadAnalytics': true,
        'canReadNewsItems': true,
        'canReadProperties': true,
        'canReadMeters': true,
        'canReadCallRecords': true,
        'canReadSettings': true,
        'canReadExternalReports': true,
        'canReadServices': true,
        'canReadMarketItemPrices': true,
        'canManageMarketItemPrices': true,
        'canReadMarketPriceScopes': true,
        'canManageMarketPriceScopes': true,
        'canReadPaymentsWithInvoices': true,
        'canReadMarketplace': true,
        'canManageMarketplace': true,
        'canReadInvoices': true,
        'canManageInvoices': true,
        'canReadMarketItems': true,
        'canManageMarketItems': true,
        'canReadTour': true,
        'canManageTour': true,
        'canReadDocuments': true,
        'canManageDocuments': true,
        'canReadMarketSetting': true,
        'canManageMarketSetting': true,
    },
    'Dispatcher': {
        'isDefault': true,
        'isEditable': true,
        'name': 'employee.role.Dispatcher.name',
        'description': 'employee.role.Dispatcher.description',
        'canManageOrganization': false,
        'canReadEmployees': true,
        'canManageEmployees': false,
        'canInviteNewOrganizationEmployees': false,
        'canManageRoles': false,
        'canManageIntegrations': false,
        'canImportBillingReceipts': false,
        'canManageProperties': true,
        'canReadTickets': true,
        'canManageTickets': true,
        'canReadContacts': true,
        'canManageContacts': true,
        'canManageContactRoles': true,
        'canManageTicketComments': true,
        'canShareTickets': true,
        'canManageMeters': true,
        'canManageMeterReadings': true,
        'canBeAssignedAsResponsible': true,
        'canBeAssignedAsExecutor': true,
        'canReadPayments': true,
        'canReadBillingReceipts': true,
        'canReadIncidents': true,
        'canManageIncidents': true,
        'canManageTicketPropertyHints': false,
        'canManagePropertyScopes': false,
        'canManageBankAccounts': false,
        'canManageBankAccountReportTasks': false,
        'canManageBankIntegrationAccountContexts': false,
        'canManageBankIntegrationOrganizationContexts': false,
        'canManageBankContractorAccounts': false,
        'canManageBankTransactions': false,
        'canManageBankAccountReports': false,
        'canManageCallRecords': true,
        'canDownloadCallRecords': false,
        'ticketVisibilityType': PROPERTY_TICKET_VISIBILITY,
        'canManageB2BApps': false,
        'canReadNewsItems': true,
        'canReadProperties': true,
        'canReadMeters': true,
        'canReadCallRecords': true,
        'canReadSettings': true,
        'canReadExternalReports': true,
        'canReadServices': true,
        'canReadMarketItemPrices': true,
        'canReadMarketPriceScopes': true,
        'canReadPaymentsWithInvoices': true,
        'canReadMarketplace': true,
        'canManageMarketplace': false,
        'canReadInvoices': true,
        'canManageInvoices': false,
        'canReadMarketItems': true,
        'canManageMarketItems': false,
        'canReadTour': true,
        'canManageTour': true,
        'canReadDocuments': true,
        'canManageDocuments': true,
        'canReadMarketSetting': true,
        'canManageMarketSetting': false,
    },
    'Manager': {
        'isDefault': true,
        'isEditable': true,
        'name': 'employee.role.Manager.name',
        'description': 'employee.role.Manager.description',
        'canManageOrganization': false,
        'canReadEmployees': true,
        'canManageEmployees': false,
        'canInviteNewOrganizationEmployees': false,
        'canManageRoles': false,
        'canManageIntegrations': false,
        'canImportBillingReceipts': false,
        'canManageProperties': true,
        'canReadTickets': true,
        'canManageTickets': true,
        'canReadContacts': true,
        'canManageContacts': true,
        'canManageContactRoles': false,
        'canManageTicketComments': true,
        'canManageMeters': true,
        'canManageMeterReadings': true,
        'canShareTickets': true,
        'canBeAssignedAsResponsible': true,
        'canBeAssignedAsExecutor': true,
        'canReadPayments': true,
        'canReadBillingReceipts': true,
        'canReadIncidents': true,
        'canManageIncidents': true,
        'canManageTicketPropertyHints': false,
        'canManagePropertyScopes': false,
        'canManageBankAccounts': false,
        'canManageBankAccountReportTasks': false,
        'canManageBankIntegrationAccountContexts': false,
        'canManageBankIntegrationOrganizationContexts': false,
        'canManageBankContractorAccounts': false,
        'canManageBankTransactions': false,
        'canManageBankAccountReports': false,
        'canManageCallRecords': true,
        'ticketVisibilityType': PROPERTY_TICKET_VISIBILITY,
        'canManageNewsItems': true,
        'canDownloadCallRecords': true,
        'canManageNewsItemTemplates': true,
        'canManageB2BApps': false,
        'canReadProperties': true,
        'canReadMeters': true,
        'canReadCallRecords': true,
        'canReadSettings': true,
        'canReadExternalReports': true,
        'canReadServices': true,
        'canReadMarketItemPrices': true,
        'canManageMarketItemPrices': true,
        'canReadMarketPriceScopes': true,
        'canManageMarketPriceScopes': true,
        'canReadPaymentsWithInvoices': true,
        'canReadMarketplace': true,
        'canManageMarketplace': false,
        'canReadInvoices': true,
        'canManageInvoices': true,
        'canReadMarketItems': true,
        'canManageMarketItems': true,
        'canReadTour': true,
        'canManageTour': true,
        'canReadDocuments': true,
        'canManageDocuments': true,
        'canReadMarketSetting': true,
        'canManageMarketSetting': true,
    },
    'Foreman': {
        'isDefault': true,
        'isEditable': true,
        'name': 'employee.role.Foreman.name',
        'description': 'employee.role.Foreman.description',
        'canManageOrganization': false,
        'canReadEmployees': true,
        'canManageEmployees': false,
        'canInviteNewOrganizationEmployees': true,
        'canManageRoles': false,
        'canManageIntegrations': false,
        'canImportBillingReceipts': false,
        'canManageProperties': false,
        'canReadTickets': true,
        'canManageTickets': true,
        'canReadContacts': true,
        'canManageContacts': true,
        'canManageContactRoles': false,
        'canManageTicketComments': true,
        'canManageMeters': true,
        'canManageMeterReadings': true,
        'canShareTickets': true,
        'canBeAssignedAsResponsible': true,
        'canBeAssignedAsExecutor': true,
        'canReadPayments': false,
        'canReadBillingReceipts': false,
        'canReadIncidents': true,
        'canManageIncidents': true,
        'canManageTicketPropertyHints': false,
        'canManagePropertyScopes': false,
        'canManageBankAccounts': false,
        'canManageBankAccountReportTasks': false,
        'canManageBankIntegrationAccountContexts': false,
        'canManageBankIntegrationOrganizationContexts': false,
        'canManageBankContractorAccounts': false,
        'canManageBankTransactions': false,
        'canManageBankAccountReports': false,
        'canManageCallRecords': true,
        'canDownloadCallRecords': false,
        'ticketVisibilityType': PROPERTY_AND_SPECIALIZATION_VISIBILITY,
        'canManageB2BApps': false,
        'canReadProperties': true,
        'canReadMeters': true,
        'canReadCallRecords': true,
        'canReadSettings': true,
        'canReadExternalReports': true,
        'canReadServices': true,
        'canReadPaymentsWithInvoices': false,
        'canReadMarketplace': true,
        'canManageMarketplace': false,
        'canReadInvoices': true,
        'canManageInvoices': false,
        'canReadMarketItemPrices': true,
        'canReadMarketPriceScopes': true,
        'canReadMarketItems': true,
        'canManageMarketItems': false,
        'canReadTour': true,
        'canManageTour': true,
        'canReadDocuments': true,
        'canManageDocuments': true,
        'canReadMarketSetting': true,
        'canManageMarketSetting': false,
    },
    'Technician': {
        'isDefault': true,
        'isEditable': true,
        'name': 'employee.role.Technician.name',
        'description': 'employee.role.Technician.description',
        'canManageOrganization': false,
        'canReadEmployees': true,
        'canManageEmployees': false,
        'canInviteNewOrganizationEmployees': false,
        'canManageRoles': false,
        'canManageIntegrations': false,
        'canImportBillingReceipts': false,
        'canManageProperties': false,
        'canReadTickets': true,
        'canManageTickets': true,
        'canReadContacts': true,
        'canManageContactRoles': false,
        'canManageTicketComments': true,
        'canManageMeters': true,
        'canManageMeterReadings': true,
        'canShareTickets': true,
        'canBeAssignedAsResponsible': true,
        'canBeAssignedAsExecutor': true,
        'canReadPayments': false,
        'canReadBillingReceipts': false,
        'canReadIncidents': true,
        'canManageIncidents': true,
        'canManageTicketPropertyHints': false,
        'canManagePropertyScopes': false,
        'canManageBankAccounts': false,
        'canManageBankAccountReportTasks': false,
        'canManageBankIntegrationAccountContexts': false,
        'canManageBankIntegrationOrganizationContexts': false,
        'canManageBankContractorAccounts': false,
        'canManageBankTransactions': false,
        'canManageBankAccountReports': false,
        'canManageCallRecords': true,
        'canDownloadCallRecords': false,
        'ticketVisibilityType': PROPERTY_AND_SPECIALIZATION_VISIBILITY,
        'canManageB2BApps': false,
        'canReadProperties': true,
        'canReadMeters': true,
        'canReadCallRecords': true,
        'canReadSettings': true,
        'canReadExternalReports': true,
        'canReadServices': true,
        'canReadPaymentsWithInvoices': false,
        'canReadMarketplace': true,
        'canManageMarketplace': false,
        'canReadInvoices': true,
        'canManageInvoices': false,
        'canReadMarketItemPrices': true,
        'canReadMarketPriceScopes': true,
        'canReadMarketItems': true,
        'canManageMarketItems': false,
        'canReadTour': true,
        'canManageTour': true,
        'canReadDocuments': true,
        'canManageDocuments': true,
        'canReadMarketSetting': true,
        'canManageMarketSetting': false,
    },
    'Contractor': {
        'isDefault': true,
        'isEditable': true,
        'name': 'employee.role.Contractor.name',
        'description': 'employee.role.Contractor.description',
        'canReadProperties': true,
        'canReadIncidents': false,
        'canReadEmployees': false,
        'canReadTickets': true,
        'canManageTickets': true,
        'canReadContacts': false,
        'canManageContacts': true,
        'canShareTickets': true,
        'canManageTicketComments': true,
        'canManageOrganization': false,
        'canManageEmployees': false,
        'canManageRoles': false,
        'canManageIntegrations': false,
        'canImportBillingReceipts': false,
        'canManageProperties': false,
        'canManageContactRoles': false,
        'canManageMeters': false,
        'canManageMeterReadings': false,
        'canBeAssignedAsResponsible': true,
        'canBeAssignedAsExecutor': true,
        'canReadPayments': false,
        'canReadBillingReceipts': false,
        'canManageIncidents': false,
        'canManageTicketPropertyHints': false,
        'canManagePropertyScopes': false,
        'canManageBankAccounts': false,
        'canManageBankAccountReportTasks': false,
        'canManageBankIntegrationAccountContexts': false,
        'canManageBankIntegrationOrganizationContexts': false,
        'canManageBankContractorAccounts': false,
        'canManageBankTransactions': false,
        'canManageBankAccountReports': false,
        'canManageCallRecords': false,
        'canDownloadCallRecords': false,
        'ticketVisibilityType': ASSIGNED_TICKET_VISIBILITY,
        'canManageB2BApps': false,
        'canReadMeters': false,
        'canReadCallRecords': false,
        'canReadSettings': false,
        'canReadExternalReports': false,
        'canReadServices': false,
        'canReadPaymentsWithInvoices': false,
        'canReadMarketplace': false,
        'canManageMarketplace': false,
        'canReadInvoices': false,
        'canManageInvoices': false,
        'canReadMarketItems': false,
        'canManageMarketItems': false,
        'canReadTour': true,
        'canManageTour': true,
        'canReadDocuments': true,
        'canManageDocuments': false,
        'canReadMarketSetting': false,
        'canManageMarketSetting': false,
    },
}

module.exports = {
    TIN_LENGTH,
    DEFAULT_ORGANIZATION_TIMEZONE,
    DELETED_ORGANIZATION_NAME,
    DEFAULT_ROLES,
    ORGANIZATION_TICKET_VISIBILITY,
    PROPERTY_TICKET_VISIBILITY,
    PROPERTY_AND_SPECIALIZATION_VISIBILITY,
    ASSIGNED_TICKET_VISIBILITY,
    TICKET_VISIBILITY_OPTIONS,
    ORGANIZATION_NAME_PREFIX_AND_QUOTES_REGEXP,
    MANAGING_COMPANY_TYPE,
    HOLDING_TYPE,
    SERVICE_PROVIDER_TYPE,
    ORGANIZATION_TYPES,
    MIN_ROLE_NAME_LENGTH,
    MAX_ROLE_NAME_LENGTH,
    MAX_ROLE_DESCRIPTION_LENGTH,
    MAX_ROLE_COUNT,
}
